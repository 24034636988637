import React, { useState, useRef } from "react";
import logo from "./logo.svg";
import domtoimage from "dom-to-image";
import "./App.css";
import QRCode from "react-qr-code";
import download from "downloadjs";
import htmlToImage from "html-to-image";

// import QrCode from "react.qrcode.generator";

function inputState([value, setState]) {
  return { value, onChange: ({ target: { value } }) => setState(value) };
}

function useInput(initial) {
  return inputState(useState(initial));
}

function App() {
  var name = useInput();
  var [edit, setEdit] = useState(true);

  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <h1>QR Generator</h1>
        {edit ? <input {...name} /> : <QRCode value={name.value} />}

        <button onClick={() => setEdit(!edit)}>
          {edit ? "Generate" : "Edit"}
        </button>

        {!edit && (
          <button
            onClick={() => {
              htmlToImage
                .toPng(document.querySelector("svg"))
                .then(function(dataUrl) {
                  download(dataUrl, "my-node.png");
                });
            }}
          >
            Download
          </button>
        )}
      </header>
    </div>
  );
}

export default App;
